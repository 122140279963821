.services-section {
    background-color: #ffffff;
    padding: 60px 20px;
}

.section-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
}

.services-slider {
    padding: 0 15px; /* Убираем слипания карточек */
}

.service-slider-item {
    padding: 0 10px; /* Отступы между карточками */
}

.service-card {
    border: none;
    background-color: #f8f9fa;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    min-height: 200px; /* Минимальная высота для выравнивания карточек */
}

.service-card-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
    text-align: center; /* Центрируем текст */
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-dots {
    bottom: -30px; /* Отодвигаем точки управления под карусель */
}

/* Уникальные классы для слайдера и элементов */
.slick-slide .service-card {
    min-width: 250px; /* Убедитесь, что карточки не слишком узкие */
}
