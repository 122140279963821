/* src/components/Footer.css */
.footer-section {
    background-color: #333;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #ffffff;
    margin: 5px 0;
}
