.about-us-section {
    background-color: #ffffff; /* Белый фон */
    padding: 60px 20px;
    border-radius: 15px; /* Скругляем углы сильнее */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Более выраженная тень */
}

.section-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #222; /* Темно-серый цвет для лучшего контраста */
    margin-bottom: 30px;
}

.about-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.15rem;
    line-height: 1.8;
    color: #666; /* Более светлый серый цвет для текста */
}
