/* Стили для блока информации */
.info-block {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 40px 20px; /* Увеличиваем внутренние отступы */
    background-color: #f8f9fa; /* Легкий фоновый цвет для выделения блока */
    border-radius: 8px; /* Сглаживаем углы блока */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень для объема */
    min-height: 200px; /* Минимальная высота блока */
}

.section-title-contact-form {
    margin-bottom: 20px;
    font-size: 24px;
}

/* Стили для иконок мессенджеров */
.contact-icons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.contact-icon {
    color: #007bff;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.contact-icon:hover {
    color: #0056b3;
}

.contact-icon svg {
    margin-right: 8px;
}

/* Стили для мобильных устройств */
@media (max-width: 576px) {
    .modal-content {
        padding: 20px;
        font-size: 16px;
    }

    .contact-icon {
        font-size: 16px;
    }

    .contact-icon svg {
        margin-right: 5px;
    }
}
