.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #7e8193;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 28px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.back-to-top.visible {
    display: flex;
}

.back-to-top:hover {
    background-color: #b7c3d0;
}
