/* src/components/ProductionGallery.css */
.production-gallery-section {
    background-color: #ffffff;
    padding: 60px 20px;
}

.section-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
}

.carousel-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover; /* Добавляет обрезку и масштабирование изображения для лучшего вида */
}
/* src/components/ProductionGallery.css */
@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }

    .carousel-item img {
        height: 300px; /* Устанавливает фиксированную высоту для мобильных устройств */
        object-fit: cover;
    }
}
