.why-choose-us-section {
    background-color: #f8f9fa;
    padding: 60px 20px;
}

.section-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
}

.reason-card {
    border: none;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column; /* Гибкая верстка карточки */
    height: 100%; /* Обеспечивает равномерную высоту карточек */
    min-height: 300px; /* Устанавливает минимальную высоту карточек */
}


.reason-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.reason-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
}

.reason-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.reason-icon {
    color: #007bff; /* Цвет иконок */
}
