/* Основные стили для header */
.header-navbar {
    font-family: 'Roboto', sans-serif;
    padding: 15px 0 !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #343a40;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: padding 0.3s, background-color 0.3s; /* Плавные переходы */
    box-sizing: border-box;
}

/* Стили для ширины и фона при прокрутке */
.header-navbar.scrolled {
    padding: 10px 0 !important; /* Уменьшаем padding при прокрутке */
    background-color: #2c2c2c !important; /* Можете изменить фон при прокрутке, если нужно */
}

/* Стили для брендовой ссылки */
.header-brand {
    font-size: 1.8rem;
    font-weight: 700;
    margin-right: 30px;
}

/* Стили для навигационных ссылок */
.navbar-nav .nav-link {
    color: white;
    font-size: 1rem;
    margin: 0 10px; /* Расстояние между ссылками */
}

.header-navbar .navbar-collapse {
    display: flex;
    justify-content: center; /* Центрируем элементы внутри Navbar.Collapse */
    flex-grow: 1; /* Позволяет Nav занимать доступное пространство */
}

/* Выделение кнопки "Отправить заявку" */
.highlight-button {
    font-weight: bold;
    background-color: #007bff !important; /* Цвет фона кнопки */
    color: white;
    border-radius: 10px;
    padding: 8px 10px !important; /* Добавьте немного отступов для кнопки */
    transition: background-color 0.3s, transform 0.2s;
}

.highlight-button:hover {
    background-color: #0056b3; /* Цвет фона при наведении */
    transform: scale(1.05); /* Легкое увеличение при наведении */
}

/* Дополнительные стили для контейнера */
.navbar-container {
    max-width: 100%; /* Обеспечиваем, что контейнер занимает всю ширину */
}

@media (max-width: 768px) {
    .header-navbar .navbar-collapse {
        display: flex;
        justify-content: left; /* Центрируем элементы внутри Navbar.Collapse */
        flex-grow: 1; /* Позволяет Nav занимать доступное пространство */
    }
}
