/* src/components/ContactInfo.css */
.contact-info-section {
    background-color: #ffffff;
    padding: 60px 20px;
    border-top: 1px solid #e1e1e1;
}

.section-title {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
}

.contact-details {
    display: flex;
    flex-direction: column;
}

.contact-item {
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 0;
}

.contact-item:last-child {
    border-bottom: none;
}

.contact-item-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.contact-item-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #555;
}

.map-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
}

.map-wrapper iframe {
    border: none;
}
