/* Основные стили для секции с лозунгом */
.slogan-section {
    background: linear-gradient(135deg, #000000 0%, #a3a4a8 100%);
    padding: 200px 0 150px; /* Добавляем отступы сверху и снизу */
    text-align: center;
    position: relative;
    overflow: hidden; /* Скрываем выходящие за пределы элементы */
}

/* Устанавливаем стили для текстового блока */
.slogan-text {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem; /* Увеличиваем размер шрифта */
    font-weight: 700;
    color: white;
    line-height: 1.4;
    max-width: 800px; /* Ограничиваем максимальную ширину */
    margin: 0 auto; /* Центрируем текст */
    position: relative;
    z-index: 1; /* Устанавливаем z-index для размещения выше фона */
}

/* Добавление декоративных элементов */
.slogan-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/react-app/src/assets/images/main_photo.webp') no-repeat center center;
    background-size: cover; /* Заполняем фон изображением */
    opacity: 0.7; /* Уменьшаем непрозрачность фона */
    z-index: 0; /* Скрываем за текстом */
}

/* Переход для адаптивности */
@media (max-width: 768px) {
    .slogan-text {
        font-size: 1.5rem; /* Уменьшаем размер шрифта для мобильных устройств */
    }
}
